import { useCreateModal, usePrevious } from '@partstech/ui/hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppNavigate } from 'app/AppRouter/useAppNavigate';
import { useGetSearchDiagrams } from 'features/diagramsList';
import { useSearchParams } from 'hooks/search';
import { getDiagramUrl } from 'utils/url/getDiagramUrl';
import { useSearchBar } from 'widgets/searchBar/context/SearchBarContext';
import { DiagramButton } from './DiagramButton';
import { DiagramsListModal } from './DiagramsListModal';
import { RelatedDiagramsButton } from './RelatedDiagramsButton';
import type { Diagram } from 'shared/api';

type Props = {
  isFiltersImprovementEnabled: boolean;
  onDismiss?: () => void;
};

export const DiagramQuickLink = ({ onDismiss, isFiltersImprovementEnabled }: Props) => {
  const navigate = useAppNavigate();
  const { searchParams, partType } = useSearchParams();
  const previousSearchParams = usePrevious(searchParams);
  const { openVehicleSelector, vehicle } = useSearchBar();

  const [shouldOpenModal, setShouldOpenModal] = useState(false);

  const { categories } = useGetSearchDiagrams({ vehicleId: vehicle?.id ?? null });

  const selectedDiagramCategory = useMemo(
    () => categories.find((category) => partType?.category?.id === category.id),
    [categories, partType?.category?.id]
  );

  const diagramCategoryTitle = useMemo(() => {
    if (vehicle?.id && selectedDiagramCategory?.name) {
      return selectedDiagramCategory.name;
    }

    return 'All categories';
  }, [selectedDiagramCategory?.name, vehicle?.id]);

  const handleSelectVehicleClick = useCallback(() => {
    openVehicleSelector();
    setShouldOpenModal(true);
  }, [openVehicleSelector]);

  const handleDiagramSelect = useCallback(
    (diagram: Diagram, vehicleId: string) => {
      navigate(getDiagramUrl(vehicleId, diagram.category?.id ?? '', diagram.id));
    },
    [navigate]
  );

  const { open: openDiagramQuickLinkModal } = useCreateModal(DiagramsListModal, {
    size: 'xl',
    onSelectVehicleClick: handleSelectVehicleClick,
    onClose: () => onDismiss?.(),
    defaultCategoryId: selectedDiagramCategory?.id ?? null,
    onDiagramSelect: handleDiagramSelect,
    vehicleId: vehicle?.id ?? null,
  });

  useEffect(() => {
    if (!shouldOpenModal) {
      return;
    }

    if (previousSearchParams && !previousSearchParams?.vehicle && vehicle?.id) {
      setShouldOpenModal(false);
      openDiagramQuickLinkModal({ onSelectVehicleClick: handleSelectVehicleClick });
    }
  }, [handleSelectVehicleClick, vehicle?.id, openDiagramQuickLinkModal, previousSearchParams, shouldOpenModal]);

  return isFiltersImprovementEnabled ? (
    <RelatedDiagramsButton onClick={openDiagramQuickLinkModal} />
  ) : (
    <DiagramButton categoryTitle={diagramCategoryTitle} onClick={openDiagramQuickLinkModal} />
  );
};
