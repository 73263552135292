import { css } from '@emotion/react';
import { Box, ButtonGroup, ModalButtons, useMedia } from '@partstech/ui';
import { useLaunchDarkly } from 'integrations/launchDarkly';
import { AccountStatus } from '../../AccountStatus';
import { StoreInfo } from '../StoreInfo';
import type { BoxProps } from '@partstech/ui';
import type { Store } from 'entities/store';
import type { SupplierAccount } from 'entities/supplierAccount';
import type { PropsWithChildren, ReactNode } from 'react';

const styles = {
  modalButton: css`
    button {
      flex: 1;
    }
  `,
};

type Props = PropsWithChildren<
  {
    store: Store;
    account?: SupplierAccount | null;
    actions?: ReactNode;
    footerElement?: ReactNode;
    showAccountStatus?: boolean;
  } & BoxProps
>;

export const CredentialsWrapper = ({
  store,
  account,
  children,
  actions,
  footerElement,
  showAccountStatus = true,
  ...props
}: Props) => {
  const { isMobile } = useMedia();

  const {
    flags: { supplierRequestStatusModal },
  } = useLaunchDarkly();

  return (
    <>
      <Box
        pt={{ sm: supplierRequestStatusModal ? 4 : 0, md: 0 }}
        display="flex"
        flexDirection={{ sm: 'column', md: 'row' }}
        minHeight={{ sm: 'calc(100 * var(--vh) - 142px)', md: 110 }}
        flexWrap="nowrap"
      >
        <StoreInfo store={store} account={account} />

        <Box
          width={{ sm: '100%', md: 97 }}
          height="100%"
          flex={{ sm: '1', md: 'initial' }}
          ml={{ sm: 0, md: 6 }}
          data-testid="content"
          {...props}
        >
          {showAccountStatus && supplierRequestStatusModal && account && (
            <Box position={{ sm: 'absolute', md: 'relative' }} left={0} top={0} mb={{ sm: 6 }}>
              <AccountStatus account={account} componentVariant="status" showMessage />
            </Box>
          )}

          {children}

          {!isMobile && (
            <ButtonGroup alignItems="center" mt={6} flexDirection={{ sm: 'column', md: 'row' }}>
              {actions}
            </ButtonGroup>
          )}

          {footerElement}
        </Box>
      </Box>

      {isMobile && (
        <ModalButtons direction="row-reverse" separated css={styles.modalButton}>
          {actions}
        </ModalButtons>
      )}
    </>
  );
};
