import { css } from '@emotion/react';
import { Box, Card, Icon, Typography } from '@partstech/ui';

const styles = {
  root: css`
    cursor: pointer;
  `,
};
type Props = {
  jobsAllowed: boolean;
  onClick: () => void;
};

export const RelatedJobsButton = ({ jobsAllowed, onClick }: Props) => (
  <Card width="100%" px={4} py={2} display="flex" gap={2} css={styles.root} onClick={onClick} borderColor="mono6">
    <Icon name="jobs" color="subtleText" />
    <Box flexGrow="1">
      <Typography>Related Jobs</Typography>
    </Box>
    {!jobsAllowed && <Icon name="upgrade" color="secondary" />}
  </Card>
);
