import { ModalView, useMedia } from '@partstech/ui';
import { memo } from 'react';
import { MediaViewer } from 'features/mediaViewer';
import { ProductMediaCarousel } from 'pages/ProductPage/ProductDetailsPage/ProductMediaCarousel';
import { Images360 } from 'shared/ui';
import type { MediaFile } from 'models';

export type FileViewerModalProps = {
  brandName: string;
  files: MediaFile[];
  images360: string[];
  partNumber: string;
  partTypeName: string;
  title?: string;
};

type Props = FileViewerModalProps & { onClose: () => void; currentIndex?: number };

export const FileViewerModal = memo(
  ({ brandName, currentIndex, files, images360, onClose, partNumber, partTypeName, title = 'View image' }: Props) => {
    const { isTablet, isMobile } = useMedia();

    const hasImages360Groups = images360.length > 0;
    const alt = `${brandName} ${partNumber} ${partTypeName ?? ''} (full size)`;

    return (
      <ModalView title={title} closeIcon="close" onClose={onClose}>
        <MediaViewer
          media={files}
          mediaSize="full"
          alt={alt}
          currentIndex={currentIndex}
          shouldHidePlaceholder={hasImages360Groups}
          variant="full"
          mediaRatio="fixed"
          mediaActionVariant={isMobile ? 'medium' : 'large'}
          display="flex"
          flexDirection={{ sm: 'column', lg: 'row' }}
          justifyContent={{ sm: 'center', md: 'space-between' }}
          height={{ md: '100%', xl: 200 }}
          minHeight={{ sm: '95dvh', md: 'initial' }}
          position={{ sm: 'fixed', md: 'static' }}
          borderWidth={0}
          width="100%"
          top={0}
          left={0}
          p={4}
          adornmentAfter={({ current, onNextClick, onPrevClick, onCurrentClick }) => (
            <>
              {!files[current] && hasImages360Groups && <Images360 images={images360} mediaRatio="fixed" />}

              <ProductMediaCarousel
                media={files}
                current={current}
                has360Images={hasImages360Groups}
                hasDiagrams={false}
                isVertical={!isTablet && !isMobile}
                onNextClick={onNextClick}
                onPrevClick={onPrevClick}
                onCurrentClick={onCurrentClick}
                onMediaClick={onCurrentClick}
              />
            </>
          )}
        />
      </ModalView>
    );
  }
);
