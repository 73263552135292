import { useMedia } from '@partstech/ui';
import { voidFunctionStub } from '@partstech/ui/utils';
import React, { useCallback } from 'react';
import { usePermissions } from 'entities/permission';
import { useAddDefaultAvailabilityFilter, useChangeFilter, useRemoveBackorderFilter } from 'hooks/search';
import { RelatedFeaturesBlock } from '../RelatedFeaturesBlock';
import { useSearchResults } from '../SearchResultsContext';
import { EmptyFilters } from './EmptyFilters';
import { Filter } from './Filter';
import { FiltersWrapper } from './FiltersWrapper';
import { SelectedFilters } from './SelectedFilters';
import type { Vehicle } from 'entities/vehicle';
import type { Filter as FilterType } from 'types/search';

type Props = {
  filters: FilterType[];
  isTiresSearch: boolean;
  selectedFiltersChip?: boolean;
  onSearch?: (e: React.MouseEvent) => void;
  onDismiss?: () => void;
  vehicle: Vehicle | null;
};

export const Filters = ({
  filters,
  isTiresSearch,
  selectedFiltersChip = false,
  onSearch = voidFunctionStub,
  onDismiss,
  vehicle,
}: Props) => {
  const { isMobile } = useMedia();

  const { isFiltersImprovementEnabled } = useSearchResults();
  const {
    shop: { retailPricingAllowed },
  } = usePermissions();

  const { changeFilter, checkedFilters, clearAll } = useChangeFilter(filters);

  useRemoveBackorderFilter();
  useAddDefaultAvailabilityFilter();

  const handleOptionChange = useCallback(
    (filter: FilterType) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const { checked, value } = e.target;
      const optionType = filter.options.find((option) => option.value === value)?.type;

      changeFilter({ name: filter.name, value, checked, type: optionType });
    },
    [changeFilter]
  );

  const handleSelectedFilterRemove = useCallback(
    (name: FilterType['name'], value: string) => {
      changeFilter({ name, value, checked: false });
    },
    [changeFilter]
  );

  if (filters.length === 0) {
    return isMobile ? <EmptyFilters onStartNewSearchClick={onSearch} /> : null;
  }

  return (
    <div data-testid="filtersBlock">
      {isMobile && (
        <RelatedFeaturesBlock
          showDiagrams={!isTiresSearch}
          showRetailPricing={retailPricingAllowed}
          onDismiss={onDismiss}
          vehicle={vehicle}
        />
      )}

      <FiltersWrapper isFiltersImprovementEnabled={isFiltersImprovementEnabled}>
        {selectedFiltersChip && checkedFilters.length > 0 && (
          <SelectedFilters
            values={checkedFilters}
            onClear={clearAll}
            onValueRemove={handleSelectedFilterRemove}
            data-testid="selectedFilters"
            mb={4}
          />
        )}

        {filters.map((filter) => (
          <Filter key={filter.name} onChange={handleOptionChange(filter)} {...filter} />
        ))}
      </FiltersWrapper>
    </div>
  );
};
