import { useForbiddenDialog, usePermissions } from 'entities/permission';

export const useSubscriptionDenied = () => {
  const { open } = useForbiddenDialog();

  const {
    member: { subscriptionAllowed },
  } = usePermissions();

  return {
    isDenied: !subscriptionAllowed,
    openDeniedDialog: open,
  };
};
