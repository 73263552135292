import { CollapsibleBlock } from '@partstech/ui';
import type { FilterProps } from './types';
import type { BoxProps } from '@partstech/ui';
import type { PropsWithChildren } from 'react';

type Props = PropsWithChildren<FilterProps & BoxProps>;

export const CollapsibleFilterWrapper = ({ children, label, isCollapsed, name, ...props }: Props) => (
  <CollapsibleBlock
    collapsed={isCollapsed}
    title={label}
    data-testid={`${name}Filter`}
    titleColor="subtleText"
    size="dense"
    contentBoxProps={{ pt: 1 }}
    {...props}
  >
    {children}
  </CollapsibleBlock>
);
