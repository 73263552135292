import { Box, Checkbox, Icon, Link } from '@partstech/ui';
import React from 'react';
import type { BoxProps } from '@partstech/ui';

type Props = BoxProps & {
  isFiltersImprovementEnabled: boolean;
  onClick: (e: React.MouseEvent) => void;
};

export const ShowAll = ({ isFiltersImprovementEnabled, onClick, ...props }: Props) => {
  if (!isFiltersImprovementEnabled) {
    return (
      <Box display="flex" alignItems="center" {...props}>
        <Icon name="add" color="primary" size="large" />
        <Link to="#" onClick={onClick} color="primary" data-testid="seeAllFilters">
          See all
        </Link>
      </Box>
    );
  }

  return (
    <Box {...props}>
      <Checkbox onClick={onClick}>Show All</Checkbox>
    </Box>
  );
};
