import React from 'react';
import { AvailabilityFilter } from './AvailabilityFilter';
import { BrandFilter } from './BrandFilter';
import { DefaultFilter } from './DefaultFilter';
import { LoadIndexOptions } from './LoadIndexFilter/LoadIndexOptions';
import { LoadRangeOptions } from './LoadRangeFilter/LoadRangeOptions';
import { SpeedRatingOptions } from './SpeedRatingFilter/SpeedRatingOptions';
import type { FilterProps } from './DefaultFilter/types';

type Props = FilterProps & {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const Filter = ({ onChange, ...filter }: Props) => {
  if (filter.name === 'manufacturers') {
    return <BrandFilter onChange={onChange} {...filter} />;
  }

  if (filter.name === 'availability') {
    return <AvailabilityFilter onChange={onChange} {...filter} />;
  }

  if (filter.name === 'TiresLoadIndex') {
    return <DefaultFilter onChange={onChange} {...filter} OptionsComponent={LoadIndexOptions} />;
  }

  if (filter.name === 'TiresSpeedRating') {
    return <DefaultFilter onChange={onChange} {...filter} OptionsComponent={SpeedRatingOptions} />;
  }

  if (filter.name === 'TiresLoadRange') {
    return <DefaultFilter onChange={onChange} {...filter} OptionsComponent={LoadRangeOptions} />;
  }

  return <DefaultFilter onChange={onChange} {...filter} />;
};
