import { useMemo } from 'react';
import { createJobsFromQuery } from 'factories';
import { CustomJobsSortBy, type Job, SortOrder } from 'shared/api';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';
import { api as generatedApi } from './GetCustomJobs.generated';
import type { GetCustomJobsQuery } from './GetCustomJobs.generated';

const emptyCustomJobs: Job[] = [];

const api = generatedApi.enhanceEndpoints({
  addTagTypes: ['CustomJob'],
  endpoints: {
    GetCustomJobs: {
      providesTags: ['CustomJob'],
      transformResponse(
        baseQueryReturnValue: GetCustomJobsQuery,
        _meta,
        arg: { sortBy?: CustomJobsSortBy; sortOrder?: SortOrder }
      ) {
        if (!baseQueryReturnValue) {
          return baseQueryReturnValue;
        }

        const jobs = baseQueryReturnValue.currentUser?.activeMember?.shop?.customJobs ?? [];

        if (!arg?.sortOrder) {
          return baseQueryReturnValue;
        }

        const sortedJobs = [...jobs].sort((a, b) => {
          const aVal = a.priority ?? 0;
          const bVal = b.priority ?? 0;
          return arg.sortOrder === 'DESC' ? bVal - aVal : aVal - bVal;
        });

        return {
          ...baseQueryReturnValue,
          currentUser: {
            ...baseQueryReturnValue.currentUser,
            activeMember: {
              ...baseQueryReturnValue.currentUser?.activeMember,
              shop: {
                ...baseQueryReturnValue.currentUser?.activeMember?.shop,
                customJobs: sortedJobs,
              },
            },
          },
        };
      },
    },
  },
});

const { useGetCustomJobsQuery } = api;

type Args = {
  skip?: boolean;
  sortOrder?: SortOrder;
};

export const useGetCustomJobs = ({ skip = false, sortOrder = SortOrder.Asc }: Args = {}) => {
  const { hasCurrentUser } = useGetCurrentUser();

  const { data, isLoading } = useGetCustomJobsQuery(
    { sortBy: CustomJobsSortBy.Priority, sortOrder },
    {
      skip: skip || !hasCurrentUser,
    }
  );

  const jobs = useMemo(() => data?.currentUser?.activeMember?.shop?.customJobs ?? emptyCustomJobs, [data]);
  const customJobs = useMemo(() => createJobsFromQuery(jobs), [jobs]);

  return {
    customJobs,
    isLoading,
  };
};
