import { Card, ImageFallback, Link, useMedia, type CardProps } from '@partstech/ui';
import { useCallback } from 'react';
import Swipe from 'react-easy-swipe';
import { useElementsNavigation } from 'shared/lib/react-dom';
import { MediaAction } from '../MediaAction';
import { MediaPlaceholder } from '../MediaPlaceholder';
import { styles } from './MediaViewer.styles';
import type { MediaFile } from 'models';
import type { MouseEvent, ReactNode } from 'react';
import type { ProductImage } from 'shared/api';

type AdornmentAfter = {
  current: number;
  onNextClick: () => void;
  onPrevClick: () => void;
  onCurrentClick: (index: number) => void;
};

export type MediaViewerProps = {
  media: Array<MediaFile | ProductImage>;
  alt: string;
  showNavigation?: boolean;
  currentIndex?: number;
  variant?: 'plain' | 'full';
  mediaSize?: 'preview' | 'medium' | 'full';
  mediaActionVariant?: 'small' | 'medium' | 'large';
  adjustableNoMediaSize?: boolean;
  shouldHidePlaceholder?: boolean;
  mediaRatio?: 'fixed' | 'flexible';
  adornmentBefore?: ReactNode;
  adornmentAfter?: (args: AdornmentAfter) => ReactNode;
  onMediaClick?: (index: number) => void;
} & CardProps;

export const MediaViewer = ({
  media,
  alt,
  showNavigation = true,
  variant = 'plain',
  mediaSize = 'medium',
  currentIndex,
  mediaActionVariant,
  adjustableNoMediaSize,
  shouldHidePlaceholder,
  mediaRatio = 'flexible',
  adornmentBefore,
  adornmentAfter,
  onMediaClick,
  ...cardProps
}: MediaViewerProps) => {
  const { isMobile } = useMedia();
  const { current, isVisible, next, prev, show, hide, currentClick } = useElementsNavigation(
    media.length,
    currentIndex
  );

  const currentMedia = media[current];
  const preview = currentMedia?.[mediaSize];
  const isVideo = currentMedia && 'isVideo' in currentMedia ? currentMedia.isVideo : false;
  const shouldShowMediaActions = media.length > 1 && showNavigation && (isMobile || isVisible);
  const isFullVariant = variant === 'full';

  const handleNextClick = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();

      next();
    },
    [next]
  );

  const handlePrevClick = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();

      prev();
    },
    [prev]
  );

  const handleMediaClick = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();

      if (!currentMedia) {
        return;
      }

      onMediaClick?.(current);
    },
    [current, currentMedia, onMediaClick]
  );

  if (!preview && !shouldHidePlaceholder) {
    return (
      <MediaPlaceholder
        adornmentBefore={adornmentBefore}
        isFullVariant={isFullVariant}
        adjustableNoMediaSize={adjustableNoMediaSize}
        {...cardProps}
      />
    );
  }

  return (
    <Card width="100%" gap={{ sm: 2, md: 4 }} onMouseEnter={show} onMouseLeave={hide} {...cardProps}>
      {adornmentBefore && adornmentBefore}

      {(preview || isVideo) && (
        <Swipe onSwipeLeft={next} onSwipeRight={prev} css={styles.wrapper}>
          {isVideo && (
            <div css={styles.mediaWrapper(isFullVariant, mediaRatio)}>
              <iframe css={styles.video} allowFullScreen src={currentMedia?.full} title="video" />
            </div>
          )}

          {!isVideo && preview && (
            <Link fill to="#" onClick={handleMediaClick} css={styles.mediaWrapper(isFullVariant, mediaRatio)}>
              <ImageFallback
                src={preview}
                alt={`${alt} #${current + 1}`}
                css={styles.image(Boolean(preview))}
                data-testid="productImage"
              />
            </Link>
          )}

          {shouldShowMediaActions && (
            <>
              <MediaAction
                iconName="chevron_left"
                position="absolute"
                variant={mediaActionVariant ?? (isFullVariant ? 'medium' : 'small')}
                left={-1}
                top="43%"
                radius={30}
                onClick={handlePrevClick}
              />
              <MediaAction
                iconName="chevron_right"
                position="absolute"
                variant={mediaActionVariant ?? (isFullVariant ? 'medium' : 'small')}
                right={-1}
                top="43%"
                radius={30}
                onClick={handleNextClick}
              />
            </>
          )}
        </Swipe>
      )}

      {adornmentAfter &&
        adornmentAfter({ current, onNextClick: next, onPrevClick: prev, onCurrentClick: currentClick })}
    </Card>
  );
};
