import { Box, Tooltip, Typography } from '@partstech/ui';
import type { TooltipProps } from '@partstech/ui';
import type { PropsWithChildren } from 'react';

type Props = PropsWithChildren<
  {
    label: string;
    description: string;
  } & Omit<TooltipProps, 'tooltip'>
>;

export const StatusTooltip = ({ label, description, children, ...props }: Props) => (
  <Tooltip
    {...props}
    tooltip={
      <Box data-testid="description">
        <Typography variant="subtitle2" color="white">
          {label}
        </Typography>

        <Typography variant="bodyText2" color="white">
          {description}
        </Typography>
      </Box>
    }
  >
    {children}
  </Tooltip>
);
