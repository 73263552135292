import { MyShopLayout } from './MyShopLayout';
import type { RouteObject } from 'react-router-dom';

const detailsPage: RouteObject = {
  path: 'details',
  lazy: () => import('pages/MyShop/Details').then((module) => ({ Component: module.Details })),
};

const usersPage: RouteObject = {
  path: 'users',
  lazy: () => import('pages/MyShop/Users').then((module) => ({ Component: module.Users })),
};

const shippingPage: RouteObject = {
  path: 'cart-shipping',
  lazy: () => import('pages/MyShop/CartShipping').then((module) => ({ Component: module.CartShipping })),
};

const suppliersPage: RouteObject = {
  path: 'suppliers/:supplierType?',
  lazy: () => import('pages/MyShop/Suppliers').then((module) => ({ Component: module.Suppliers })),
};

const retailPricingPage: RouteObject = {
  path: 'retail-pricing',
  lazy: () => import('pages/MyShop/RetailPricing').then((module) => ({ Component: module.RetailPricing })),
};

const paymentsPage: RouteObject = {
  path: 'payments',
  lazy: () => import('pages/MyShop/Payments').then((module) => ({ Component: module.Payments })),
};

const preferredBrandsPage: RouteObject = {
  path: 'brand-preferences',
  lazy: () => import('pages/MyShop/PreferredBrands').then((module) => ({ Component: module.PreferredBrands })),
};

const rewardsPage: RouteObject = {
  path: 'rewards',
  lazy: () => import('pages/MyShop/Rewards').then((module) => ({ Component: module.RewardsPage })),
};

const subscriptionPage: RouteObject = {
  path: 'subscription',
  lazy: () => import('pages/MyShop/Subscription').then((module) => ({ Component: module.Subscription })),
};

const paymentsHistoryPage: RouteObject = {
  path: 'subscription/payment-history',
  lazy: () => import('./Subscription/PaymentsHistory').then((module) => ({ Component: module.PaymentsHistory })),
};

export const myShopPages: RouteObject = {
  path: '/my-shop/*',
  element: <MyShopLayout />,
  children: [
    detailsPage,
    usersPage,
    shippingPage,
    suppliersPage,
    paymentsPage,
    retailPricingPage,
    preferredBrandsPage,
    rewardsPage,
    subscriptionPage,
    paymentsHistoryPage,
  ],
};
