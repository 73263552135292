import { css } from '@emotion/react';
import { Box, Icon, Typography } from '@partstech/ui';
import { AccountStatus as Statuses } from 'shared/api';
import { StatusTooltip } from './StatusTooltip';
import type { IconProps, Theme, ThemeColorName, TooltipProps } from '@partstech/ui';
import type { SupplierAccount } from 'entities/supplierAccount';
import type { PropsWithChildren } from 'react';

const styles = {
  statusContainer: (background: ThemeColorName) => (theme: Theme) => css`
    display: inline-flex;
    align-items: center;
    background: ${theme.color[background]};

    border: 2px solid ${theme.color[background]};
    border-radius: 0 0 ${theme.sizing(2)} 0;

    padding: 2px 6px;
  `,
};

type Label = {
  name: IconProps['name'];
  color: ThemeColorName;
};

const iconVariant: Record<Statuses, Label> = {
  [Statuses.Processing]: { name: 'pending', color: 'neutral' },
  [Statuses.Approved]: { name: 'check_circle', color: 'positive' },
  [Statuses.NotApproved]: { name: 'warning', color: 'negative' },
  [Statuses.HelpRequested]: { name: 'help', color: 'neutral' },
  [Statuses.PaymentPending]: { name: 'pending', color: 'neutral' },
  [Statuses.Pending]: { name: 'pending', color: 'neutral' },
  [Statuses.PartstechHelpRequested]: { name: 'pending', color: 'neutral' },
  [Statuses.ReadyForReview]: { name: 'pending', color: 'neutral' },
  [Statuses.SupplierApprovalNeeded]: { name: 'pending', color: 'neutral' },
};

type Props = PropsWithChildren<
  {
    account: SupplierAccount;
    showMessage?: boolean;
    componentVariant?: 'status' | 'default';
  } & Omit<TooltipProps, 'tooltip'>
>;

export const AccountStatus = ({ account, showMessage, children, componentVariant = 'default', ...props }: Props) => {
  const { status, statusMessage, customerDescription, supplier } = account;

  const iconProps: Label = supplier?.comingSoon ? { name: 'timer', color: 'negative' } : iconVariant[status];

  const description = supplier?.comingSoon
    ? `You can pre-register for ${supplier?.name} and we will notify you when they become available`
    : (customerDescription ?? '');

  if (componentVariant === 'status') {
    // TODO: Use Status component from @partstech/ui after it is implemented new design
    return (
      <StatusTooltip {...props} label={statusMessage} description={description}>
        <Box css={styles.statusContainer(iconProps.color)}>
          <Box display="flex" pl={0.25}>
            <Icon color="white" name={iconProps.name} size="sm" />
          </Box>

          {showMessage && (
            <Box pr={0.5} pl={1}>
              <Typography textTransform="uppercase" component="div" variant="overline" color="white">
                {statusMessage}
              </Typography>
            </Box>
          )}
        </Box>
      </StatusTooltip>
    );
  }

  return (
    <StatusTooltip {...props} label={statusMessage} description={description}>
      <Box display="flex" alignItems="center" gap={1}>
        <Icon {...iconProps} size="large" />

        {showMessage && (
          <Typography component="div" variant="bodyText2" color={iconProps.color}>
            {statusMessage}
          </Typography>
        )}
      </Box>
    </StatusTooltip>
  );
};
