import { ModalView } from '@partstech/ui';
import { useCallback } from 'react';
import { JobsList } from 'features/jobsList';
import { useStartSearch } from 'features/searchForm';
import type { ModalViewProps } from '@partstech/ui';
import type { Vehicle } from 'entities/vehicle';
import type { Job } from 'models';

type Props = ModalViewProps & {
  vehicle: Vehicle | null;
};

const emptyJobs: Job[] = [];

export const RelatedJobsModal = ({ vehicle, onClose }: Props) => {
  const startSearch = useStartSearch();

  const handleChange = useCallback(
    (job: Job) => {
      startSearch(job, vehicle);
      onClose?.();
    },
    [onClose, startSearch, vehicle]
  );

  return (
    <ModalView onClose={onClose} title="Related Jobs">
      <JobsList options={vehicle?.jobs ?? emptyJobs} onChange={handleChange} hasVehicle={Boolean(vehicle)} />
    </ModalView>
  );
};
