import { isRejected, isRejectedWithValue } from '@reduxjs/toolkit';
import { useEffect, useRef } from 'react';
import { useForbiddenDialog } from 'entities/permission';
import { permissionDeniedListener } from 'store/middleware';
import type { ResponseError } from 'shared/api';

const hasPermissionDeniedError = (message?: string) => message === 'Forbidden' || message === 'Permission Denied';

export const usePermissionDeniedListener = () => {
  const { open } = useForbiddenDialog();

  const openForbiddenDialogRef = useRef(open);

  useEffect(() => {
    openForbiddenDialogRef.current = open;
  }, [open]);

  useEffect(() => {
    const unsubscribe = permissionDeniedListener.startListening({
      predicate: (action) => {
        if (isRejectedWithValue(action)) {
          return hasPermissionDeniedError((action.payload as ResponseError).message);
        }

        if (isRejected(action)) {
          return hasPermissionDeniedError(action.error.message);
        }

        return false;
      },

      effect: () => openForbiddenDialogRef.current(),
    });

    return () => {
      unsubscribe();
    };
  }, []);
};
