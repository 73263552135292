import { Box, Loader, useMedia } from '@partstech/ui';
import { lazy, memo, Suspense, useMemo } from 'react';
import { matchPath, Outlet, useMatch } from 'react-router-dom';
import { useAppLocation } from 'app/AppRouter/useAppLocation';
import { HeaderSkeleton } from 'app/contexts/AppProvider/LayoutSkeleton/HeaderSkeleton';
import { useLayoutContext } from 'app/contexts/LayoutProvider/LayoutContext';
import { ProfileBar } from 'components/ProfileBar';
import { defaultUIOffset, Routes } from 'constant';
import { CartBarLoader } from 'features/cart';
import { SupportButton } from 'features/support';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';
import { cartBarWidth, styles } from './Layout.styles';
import type { PropsWithChildren } from 'react';

const Header = lazy(() =>
  import(/* webpackChunkName: "component.Header" */ 'components/Layout/Header').then((module) => ({
    default: module.Header,
  }))
);
const CartBar = lazy(() =>
  import(/* webpackChunkName: "component.CartBar" */ 'widgets/CartBar').then((module) => ({
    default: module.CartBar,
  }))
);
const Notifications = lazy(() =>
  import(/* webpackChunkName: "ui.Notifications" */ 'components/Layout/Notifications').then((module) => ({
    default: module.Notifications,
  }))
);

export const Layout = memo(({ children }: PropsWithChildren) => {
  const { pathname } = useAppLocation();
  const { isMobile, isWide, isPrint } = useMedia();

  const { hasCurrentUser, email, username } = useGetCurrentUser();

  const isCartTransferPage = Boolean(useMatch(Routes.CART_TRANSFER));
  const isCartPage = Boolean(useMatch(Routes.CART)) || isCartTransferPage;

  const defaultUserValues = useMemo(() => ({ email: email ?? '', username: username ?? '' }), [email, username]);

  const showCartBar = useMemo(() => {
    if (isMobile || !hasCurrentUser) {
      return false;
    }

    if (isWide) {
      return [
        matchPath(Routes.DASHBOARD, pathname),
        matchPath(Routes.SEARCH_RESULTS, pathname),
        matchPath(Routes.PRODUCT_PAGE, pathname),
        matchPath(Routes.STOCK_ORDER_NEW, pathname),
        matchPath(Routes.STOCK_ORDER_TEMPLATE, pathname),
        matchPath(Routes.STOCK_ORDER_TEMPLATES, pathname),
        isCartPage,
      ].some(Boolean);
    }

    return true;
  }, [hasCurrentUser, isCartPage, isMobile, isWide, pathname]);

  const { headerRef, headerHeight, uiOffsetProfileBar } = useLayoutContext();

  return (
    <>
      {!isPrint && (
        <Box
          zIndex="toolbar"
          position="fixed"
          left={!isMobile && hasCurrentUser ? `${uiOffsetProfileBar.left ?? 0}px` : 0}
          top={0}
          right={0}
          background="white"
          ref={headerRef}
          css={styles.header(hasCurrentUser ? uiOffsetProfileBar : undefined)}
        >
          <Suspense fallback={<HeaderSkeleton />}>
            <Header />
            <Notifications />
          </Suspense>
        </Box>
      )}

      <Box
        pt={isPrint ? undefined : `${headerHeight}px`}
        pl={!isMobile && !isPrint ? `${hasCurrentUser ? defaultUIOffset.left : 0}px` : undefined}
      >
        {!isPrint && hasCurrentUser && <ProfileBar />}

        <Box height="100%" pr={{ sm: 0, xl: showCartBar && !isPrint ? `${cartBarWidth}px` : 0 }}>
          <Suspense
            fallback={<Loader size="medium" display="flex" justifyContent="center" alignItems="center" p={5} />}
          >
            {children ?? <Outlet />}
          </Suspense>
        </Box>

        {!isPrint && showCartBar && (
          <Box
            position="fixed"
            background="white"
            width={`${cartBarWidth}px`}
            css={styles.cartBar(headerHeight, isCartPage)}
          >
            <Suspense fallback={<CartBarLoader isVisible />}>
              <CartBar />
            </Suspense>
          </Box>
        )}
      </Box>

      <Box position="fixed" bottom={5} right={2.5} zIndex="popover">
        <SupportButton
          mode="globalSupport"
          defaultValues={defaultUserValues}
          className="support-button"
          leadingIcon="help"
          css={styles.supportButton}
        >
          Support
        </SupportButton>
      </Box>
    </>
  );
});
