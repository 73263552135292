import { Box, Button, Typography, useModalHistory } from '@partstech/ui';
import { Fragment, useCallback } from 'react';
import { AccountStatus as Statuses } from 'shared/api';
import { CredentialsWrapper } from '../CredentialsWrapper';
import type { CommonAccountFlowPaths } from '../../../types';

const generateStatusContent = (status: Statuses, name: string) => {
  switch (status) {
    case Statuses.Approved:
      return {
        messages: [
          `Your ${name} connection is approved.`,
          `You can remove or edit the connection, including switching your primary ${name} store, by clicking below.`,
        ],
        editButtonName: 'Edit supplier',
        removeButtonName: 'Delete supplier',
      };

    case Statuses.NotApproved:
      return {
        messages: [
          `Your connection request was not approved by ${name}.`,
          `If you believe you submitted incorrect credentials or selected the wrong ${name} store, click below to edit and resubmit the request. You can also remove the request.`,
        ],
        editButtonName: 'Edit request',
        removeButtonName: 'Delete request',
      };

    default:
      return {
        messages: [
          `Your connection request is being reviewed by ${name}. They may reach out to you for more information.`,
          `If you believe you submitted incorrect credentials or selected the wrong ${name} store, click below to edit and resubmit the request. You can also cancel.`,
        ],
        editButtonName: 'Edit request',
        removeButtonName: 'Cancel request',
      };
  }
};

export const Review = () => {
  const { state: { account, selectedStore } = {}, push } = useModalHistory<CommonAccountFlowPaths, 'review'>();

  const handleDeleteClick = useCallback(() => {
    if (!account) {
      return;
    }

    push('delete', { account });
  }, [account, push]);

  const handleEditClick = useCallback(() => {
    if (!account) {
      return;
    }

    push('edit', { account, selectedStore });
  }, [account, push, selectedStore]);

  if (!account || !account.store) {
    return null;
  }

  const { status, store } = account;

  const { messages, editButtonName, removeButtonName } = generateStatusContent(
    status,
    account?.supplier?.name ?? 'your'
  );

  return (
    <CredentialsWrapper
      store={store}
      account={account}
      actions={
        <>
          <Button onClick={handleEditClick} className="edit-button" data-testid="editButton">
            {editButtonName}
          </Button>

          <Button
            variant="secondary"
            leadingIcon="delete"
            color="negative"
            onClick={handleDeleteClick}
            className="delete-button"
            data-testid="deleteButton"
          >
            {removeButtonName}
          </Button>
        </>
      }
      height={{ sm: '100%', md: 'auto' }}
      flex={{ sm: '1', md: 'initial' }}
      display="flex"
      flexDirection="column"
      justifyContent={{ sm: 'space-between', md: 'flex-start' }}
    >
      <Box mb={6}>
        {messages.map((message, index) => (
          <Fragment key={index}>
            <Typography>{message}</Typography>

            {index !== messages.length - 1 && <br />}
          </Fragment>
        ))}
      </Box>
    </CredentialsWrapper>
  );
};
