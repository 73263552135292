import { makeContext } from '@partstech/ui/utils';
import type { Product } from 'models';
import type { SearchErrorData } from 'store/features/search/settings';
import type { CheckedFilters, Filter } from 'types/search';

export type SearchResultsContextValue = {
  checkedFilters: Partial<CheckedFilters>;
  isFiltersImprovementEnabled: boolean;
  filters: Filter[];
  filteredProducts: Product[];
  products: Product[];
  totals: Record<number, number>;
  loadingIds: number[];
  loadedIds: number[];
  isAllLoaded: boolean;
  isActiveSupplierAccountLoaded: boolean;
  isCatalogLoaded: boolean;
  getErrorsBySupplierAccountId: (supplierAccountId: string | null) => string[];
  getErrorsMap: () => Record<string, SearchErrorData>;
};

export const [useSearchResults, SearchResultsContextProvider] = makeContext<SearchResultsContextValue>();
