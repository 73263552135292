import { Box, Checkbox, RadioButton, Tooltip } from '@partstech/ui';
import React from 'react';
import type { FilterOption, FilterOptionType } from 'types/search';

type Props = {
  adornmentAfter?: React.ReactNode;
  checked: boolean;
  disabled?: boolean;
  isFiltersImprovementEnabled?: boolean;
  label?: string;
  name: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  option: FilterOption;
  showTooltip?: boolean;
  type?: FilterOptionType;
};

export const Option = ({
  adornmentAfter,
  checked,
  disabled,
  isFiltersImprovementEnabled,
  label,
  name,
  onChange,
  option,
  showTooltip,
  type = 'checkbox',
}: Props) => {
  const Component = type === 'radio' ? RadioButton : Checkbox;

  if (!isFiltersImprovementEnabled) {
    return (
      <Tooltip
        tooltip={label}
        isHidden={!showTooltip}
        fullWidth
        verticalAlign="start"
        positioning="vertical"
        offsetY={-77}
        offsetX={26}
      >
        <Component
          data-testid={type === 'radio' ? 'radio-button' : 'checkbox'}
          checked={checked}
          id={String(option.value)}
          key={option.value}
          name={name}
          title={option.name}
          onChange={onChange}
          value={option.value}
          disabled={disabled}
        >
          {option.name} {typeof option.count === 'number' && `(${option.count})`}
          {adornmentAfter}
        </Component>
      </Tooltip>
    );
  }

  return (
    <Tooltip
      tooltip={label}
      isHidden={!showTooltip}
      fullWidth
      verticalAlign="start"
      positioning="vertical"
      offsetY={-77}
      offsetX={26}
    >
      <Box display="flex" justifyContent="space-between" py={1}>
        <Component
          data-testid={type === 'radio' ? 'radio-button' : 'checkbox'}
          checked={checked}
          id={String(option.value)}
          key={option.value}
          name={name}
          title={option.name}
          onChange={onChange}
          value={option.value}
          disabled={disabled}
        >
          {option.name}
          {adornmentAfter}
        </Component>

        {typeof option.count === 'number' && <Box>{option.count}</Box>}
      </Box>
    </Tooltip>
  );
};
