import { css } from '@emotion/react';
import { Box, Icon, Result } from '@partstech/ui';
import React from 'react';
import type { BoxProps, ResultProps, Theme } from '@partstech/ui';
import type { PropsWithChildren, ReactNode } from 'react';

const style = {
  infoIcon: (theme: Theme) => css`
    width: ${theme.sizing(10)};
    height: ${theme.sizing(10)};
    font-size: ${theme.sizing(10)};
  `,
};

type Props = BoxProps &
  Pick<ResultProps, 'className'> &
  PropsWithChildren<{
    extraButtons?: Array<React.ReactNode>;
    hasOptions: boolean;
    hasVehicle: boolean;
    noOptionsText: string;
    noVehicleText: ReactNode;
    noOptionsIcon?: ReactNode;
  }>;

/**
 * Wrapper to show `Select Vehicle` button instead of content if hasVehicle === false
 */
export const VehicleRequiredView = ({
  children,
  extraButtons,
  hasOptions,
  hasVehicle,
  noOptionsText,
  noVehicleText,
  noOptionsIcon,
  ...props
}: Props) => {
  if (!hasVehicle) {
    return (
      <Result
        width="100%"
        m="auto"
        message={noVehicleText}
        customIcon={<Icon name="directions_car" color="subtleText" css={style.infoIcon} />}
        extraButtons={extraButtons}
        {...props}
      />
    );
  }

  if (!hasOptions) {
    return <Result width="100%" m="auto" message={noOptionsText} customIcon={noOptionsIcon} {...props} />;
  }

  return <Box {...props}>{children}</Box>;
};
