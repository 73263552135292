import { Box, Card, useMedia } from '@partstech/ui';
import { memo } from 'react';
import type { PropsWithChildren } from 'react';

type Props = { isFiltersImprovementEnabled: boolean };

export const FiltersWrapper = memo(({ children, isFiltersImprovementEnabled }: PropsWithChildren<Props>) => {
  const { isMobile } = useMedia();

  if (isMobile && !isFiltersImprovementEnabled) {
    return children;
  }

  if (!isFiltersImprovementEnabled) {
    return (
      <Box width={{ sm: '100%', md: 'auto' }} background={{ sm: 'white', md: 'inherit' }}>
        {children}
      </Box>
    );
  }

  return (
    <Card width={{ sm: '100%', md: 'auto' }} p={{ sm: 0, md: 4 }} mb={4} elevation={null}>
      {children}
    </Card>
  );
});
