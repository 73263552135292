import { css } from '@emotion/react';
import { Card, Icon, Typography } from '@partstech/ui';

const styles = {
  root: css`
    cursor: pointer;
  `,
};
type Props = {
  onClick: () => void;
};

export const RelatedDiagramsButton = ({ onClick }: Props) => (
  <Card width="100%" px={4} py={2} display="flex" gap={2} css={styles.root} onClick={onClick} borderColor="mono6">
    <Icon name="vehicle_diagrams" color="subtleText" />
    <Typography>Related Diagrams</Typography>
  </Card>
);
