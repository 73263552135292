import { useCreateModal } from '@partstech/ui/hooks';
import { usePermissions } from 'entities/permission';
import { RelatedJobsButton } from './RelatedJobsButton';
import { RelatedJobsModal } from './RelatedJobsModal';
import type { Vehicle } from 'entities/vehicle';

type Props = {
  onDismiss?: () => void;
  vehicle: Vehicle | null;
};

export const RelatedJobs = ({ vehicle, onDismiss }: Props) => {
  const { open: openRelatedJobsModal } = useCreateModal(RelatedJobsModal, {
    size: 'xl',
    onClose: onDismiss,
    title: 'Related Jobs',
    vehicle,
  });

  const {
    shop: { jobsAllowed },
  } = usePermissions();

  return <RelatedJobsButton onClick={openRelatedJobsModal} jobsAllowed={jobsAllowed} />;
};
