import { ModalView } from '@partstech/ui';
import { useCallback } from 'react';
import { Filters } from 'pages/SearchResults/Filters';
import { useSearchResults } from 'pages/SearchResults/SearchResultsContext';
import { SearchTypes } from 'types/search';
import { useSearchBar } from '../../../context/SearchBarContext';

type Props = {
  onSearch: () => void;
  onClose: () => void;
};

export const RefineModal = ({ onClose, onSearch }: Props) => {
  const handleOnSearch = useCallback(() => {
    onSearch();
    onClose();
  }, [onClose, onSearch]);

  const { vehicle, searchFormType } = useSearchBar();
  const { filters } = useSearchResults();

  return (
    <ModalView title="Refine" onClose={onClose} closeIcon="arrow_back">
      <Filters
        filters={filters}
        onSearch={handleOnSearch}
        onDismiss={onClose}
        vehicle={vehicle}
        isTiresSearch={searchFormType === SearchTypes.TIRES}
      />
    </ModalView>
  );
};
